/* eslint-disable max-len */
import { RISK_SECTION_HIGH, RISK_SECTION_LOW, RISK_SECTION_MEDIUM } from '@consts/risk';
import {
  formatBMIQuestion,
  formatOption,
  formatQuestion,
  formatResult,
  formatRisk,
  formatYesNoQuestion,
} from '@/src/common/helpers/quiz';
import { COL_SIZE_FULL, QUESTION_TYPE_GENDER } from '@consts/quiz';
import { customAgeScores, customBmiScores } from '@/src/common/helpers/osteoporosis';

// HACK: Patients below 40 will always be low risk, keeping the low att -500 to be able to make room for redicing the score

export const riskSettings = [
  formatRisk(RISK_SECTION_LOW, 'common.low', 'risk.low', -500, 6),
  formatRisk(RISK_SECTION_MEDIUM, 'common.medium', 'risk.medium', 7, 12),
  formatRisk(RISK_SECTION_HIGH, 'common.high', 'risk.high', 13, 99999),
];

export const resultSettings = [
  formatResult(
    'Du bedöms ha låg risk för att ha eller utveckla osteoporos. Känner du dig osäker och upplever att du har risk för osteoporos rekommenderar vi dig att du vänder dig till vårdgivare för vidare diskussioner.',
    '',
    -500,
    6,
  ),
  formatResult(
    'Du bedöms ha måttlig risk för att ha eller utveckla osteoporos. Vi rekommenderar att du kontakt din läkare för att få en individanpassad bedömning av ditt tillstånd. En läkare kan göra undersökningar för att konstatera om du har osteoporos eller inte samt sätta in eventuella åtgärder som kan behövas för att hantera situationen på bästa möjliga sätt.',
    '',
    7,
    12,
  ),
  formatResult(
    'Du bedöms ha en hög risk för att ha eller utveckla osteoporos. Du bör kontakta din läkare för att få en individanpassad bedömning av ditt tillstånd. En läkare kan göra undersökningar för att konstatera om du har osteoporos eller inte och sätta in eventuella åtgärder som kan behövas för att hantera situationen på bästa möjliga sätt.',
    '',
    13,
    99999,
  ),
];

export const questions = [
  formatQuestion(
    'question1',
    'Vilket kön har du?',
    [
      formatOption('Kvinna', 2, 0, COL_SIZE_FULL, COL_SIZE_FULL, 'woman'),
      formatOption('Man', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL, 'man'),
      formatOption('Vill ej svara', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL, 'other'),
    ],
    QUESTION_TYPE_GENDER,
  ),
  formatBMIQuestion(
    'question2',
    'bmi.title',
    customBmiScores,
    customAgeScores,
  ),
  formatYesNoQuestion(
    'question3',
    'Har du drabbats av en eller flera frakturer (benbrott) i vuxen ålder?',
    4,
  ),
  formatYesNoQuestion(
    'question4',
    'Har någon av dina föräldrar haft en höftfraktur (brutit höften)?',
    2,
  ),
  formatYesNoQuestion(
    'question5',
    'Röker du?',
    1,
  ),
  formatYesNoQuestion(
    'question6',
    'Genomgår du en östrogenbehandling?',
    2,
  ),
  formatYesNoQuestion(
    'question7',
    'Har du ledgångsreumatism (reumatoid artrit)?',
    2,
  ),
];

export const references = [
  'FRAX indicates Fracture Risk Assessment Tool; ORAI, Osteoporosis Risk Assessment Instrument; OSIRIS, Osteoporosis Index of Risk; OST, Osteoporosis Self-assessment Tool; SCORE, Simple Calculated Osteoporosis Risk Estimation Tool.',
  '<a href="https://www.uspreventiveservicestaskforce.org/uspstf/recommendation/osteoporosis-screening#bootstrap-panel--10">https://www.uspreventiveservicestaskforce.org/uspstf/recommendation/osteoporosis-screening#bootstrap-panel--10</a>',
  '<a href="https://www.physio-pedia.com/The_Osteoporosis_Risk_Assessment_Instrument_(ORAI)">https://www.physio-pedia.com/The_Osteoporosis_Risk_Assessment_Instrument_(ORAI)</a>',
  '<a href="https://jamanetwork.com/journals/jama/fullarticle/2685995">https://jamanetwork.com/journals/jama/fullarticle/2685995</a>',
  '<a href="https://www.uspreventiveservicestaskforce.org/uspstf/recommendation/osteoporosis-screening">https://www.uspreventiveservicestaskforce.org/uspstf/recommendation/osteoporosis-screening</a>',
];
