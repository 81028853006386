export const customBmiScores = [
  {
    condition: (bmi: number) => bmi < 19,
    score: 4,
  },
  {
    condition: (bmi: number) => bmi >= 19 && bmi <= 24,
    score: 2,
  },
  {
    condition: (bmi: number) => bmi > 24,
    score: 0,
  },
];

export const customAgeScores = [
  {
    condition: (age: number) => age < 40,
    score: -300, // HACK: Should always be low risk if below 40
  },
  {
    condition: (age: number) => age >= 40 && age < 55,
    score: 0,
  },
  {
    condition: (age: number) => age >= 55 && age <= 65,
    score: 2,
  },
  {
    condition: (age: number) => age >= 66 && age <= 75,
    score: 3,
  },
  {
    condition: (age: number) => age > 75,
    score: 5,
  },
];
